/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetRefuseMissionReasonListQuery,
  usePatchWorkflowMissionStateMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Dialog,
  MenuItem,
} from '@mui/material'
import { toast } from 'react-toastify'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import {
  EtatMissionnement,
  TypeRefus,
  type MissionnementEtatRequest,
  type MissionnementMotifRefus,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const refuseMissionSchema = Yup.object().shape<Shape<MissionnementEtatRequest>>({
  motifRefus: Yup.number().moreThan(-1, 'Le motif du refus est obligatoire')
    .required('Le motif du refus est obligatoire'),
  commentaire: Yup.string().required('Le commentaire est obligatoire'),
}).required()

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowRefuseMissionButtonProps {
  caseId: string;
  isUrgentCase: boolean;
}

const CaseWorkflowRefuseMissionButton: React.FC<CaseWorkflowRefuseMissionButtonProps> = ({ caseId, isUrgentCase }) => {
  const isReadOnly = useIsReadOnly()
  const [ openMain, setOpenMain ] = useState<boolean>(false)
  const [ openConfirm, setOpenConfirm ] = useState<boolean>(false)

  const {
    currentData: refuseReasonList = [],
    isFetching: isFetchingRefuseReasonList,
  } = useGetRefuseMissionReasonListQuery({ IsUrgent: isUrgentCase, TypeRefus: TypeRefus.REFUSE })

  const [ submitUpdateMission ] = usePatchWorkflowMissionStateMutation()

  const onSubmit = async (values: MissionnementEtatRequest, { setSubmitting, resetForm }: FormikHelpers<MissionnementEtatRequest>) => {
    setSubmitting(false)
    const response = await submitUpdateMission({ caseId: caseId, data: values })
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'enregistrement.")
    } else {
      resetForm()
      setOpenMain(false)
    }
  }

  const formikForm: FormikContextType<MissionnementEtatRequest> = useForm<MissionnementEtatRequest>(
    {
      onSubmit: onSubmit,
      validationSchema: refuseMissionSchema,
      initialValues: {
        etat: EtatMissionnement.Refuse,
        motifRefus: -1,
        commentaire: '',
      },
    },
  )

  const onCloseMain = () => {
    setOpenMain(false)
  }

  const onCloseConfirm = () => {
    setOpenConfirm(false)
    formikForm.submitForm()
  }

  return (
    <>
      {
        isFetchingRefuseReasonList ?
          <CircularProgress /> :
          <CaseWorkflowStyledComponents.Button
            variant="outlined"
            onClick={() => setOpenMain(true)}
            disabled={isReadOnly}
          >
            Refuser
          </CaseWorkflowStyledComponents.Button>
      }
      <Dialog
        open={openMain}
        onClose={onCloseMain}
        maxWidth="sm"
        fullWidth
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          Refuser la mission
          <CloseButton handleClose={onCloseMain} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <FormBoldTitle required>
              Motif du refus
            </FormBoldTitle>
            <Field
              component={Select}
              name="motifRefus"
              displayEmpty
              autoWidth={true}
            >
              <MenuItem value={-1}>
                Sélectionner
              </MenuItem>
              {
                refuseReasonList.map((motif: MissionnementMotifRefus, index) => (
                  <MenuItem
                    value={motif.id}
                    key={`${motif.id}-${index}`}
                  >
                    {motif.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            <React.Fragment>
              <FormBoldTitle required>
                Commentaire
              </FormBoldTitle>
              <Field
                component={TextField}
                name="commentaire"
                placeholder="Votre commentaire"
                multiline
                rows={3}
              />
            </React.Fragment>
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onCloseMain}
            >
              Annuler
            </LongButton>
            <LongButton
              onClick={() => setOpenConfirm(true)}
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={onCloseConfirm}
        maxWidth="xs"
        fullWidth
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          Êtes-vous sûr de vouloir refuser la mission ?
          <CloseButton handleClose={onCloseConfirm} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <CaseWorkflowStyledComponents.DialogAction>
          <LongButton
            variant="outlined"
            onClick={() => {onCloseConfirm(); onCloseMain()}}
          >
            Annuler
          </LongButton>
          <LongButton
            type="submit"
            onClick={onCloseConfirm}
            variant="contained"
            disabled={formikForm.isSubmitting}
          >
            Confirmer
          </LongButton>
        </CaseWorkflowStyledComponents.DialogAction>
      </Dialog>
    </>
  )
}

export default CaseWorkflowRefuseMissionButton
