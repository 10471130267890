/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostLegalDocumentMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
} from '@mui/material'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CaseWorkflowStyledComponents from 'pages/CaseWorkflowPage/CaseWorkflowComponents/CaseWorkflowStyledComponents'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  DocumentJuridique,
  DocumentJuridiqueMonEtablissementCreatePayload,
  DocumentJuridiqueType,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DateFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

/* Type declarations -------------------------------------------------------- */
interface LegalDocumentRequest extends DocumentJuridiqueMonEtablissementCreatePayload {}

const legalDocumentSchema = Yup.object().shape<Shape<LegalDocumentRequest>>({
  Nom: Yup.string(),
}).required()

/* Component declaration ---------------------------------------------------- */
interface LegalDocumentsButtonProps {
  legalDocument?: DocumentJuridique;
  legalType: DocumentJuridiqueType;
}

const LegalDocumentsButton: React.FC<LegalDocumentsButtonProps> = ({ legalDocument, legalType }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)
  const [ file, setFile ] = useState<File>()

  const [
    submitLegalDocument,
  ] = usePostLegalDocumentMutation()

  const onSubmit = async (values: LegalDocumentRequest, { setSubmitting, resetForm }: FormikHelpers<LegalDocumentRequest>) => {
    setSubmitting(false)
    const response = await submitLegalDocument({
      ...values,
      Fichier: file,
      DateValiditeMax: values.IsPermanent ? '0001-01-01T00:00:00.000Z' : values.DateValiditeMax,
    })
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'envoi du document.")
    } else {
      resetForm()
      setOpen(false)
    }
  }

  const formikForm: FormikContextType<LegalDocumentRequest> = useForm<LegalDocumentRequest>(
    {
      initialValues: {
        DateValiditeMax: legalDocument?.dateValiditeMax || '',
        IsObligatoire: legalDocument?.isObligatoire || false,
        Nom: legalDocument?.nom || '',
        'TypeDocument.Id': legalType.id,
        IsPermanent: false,
      },
      onSubmit: onSubmit,
      validationSchema: legalDocumentSchema,
    },
  )

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      const newFile = Object.values(e.target.files ?? {})[0]
      setFile(newFile)
      formikForm.setFieldValue('Nom', newFile.name)
    }
  }

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {legalDocument ? 'Modifier' : 'Ajouter'}
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          {legalDocument ? 'Modifier le document juridique' : 'Ajouter un document juridique'}
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <div>
              <FormBoldTitle required>
                Ajouter une pièce jointe
              </FormBoldTitle>
              <AttachmentButton
                name="legal-document"
                onChange={handleOnFileChange}
                accept=".pdf"
                multiple={false}
              >
                <Button
                  variant="outlined"
                  component="span"
                >
                  {file?.name || 'Choisir un fichier'}
                </Button>
              </AttachmentButton>
              <FormBoldTitle>
                Nom du document
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom du document"
                name="Nom"
              />
              <FormBoldTitle required={!formikForm.values.IsPermanent}>
                Date de validité
              </FormBoldTitle>
              <DateFieldContainer>
                <FormikDatePicker
                  name="DateValiditeMax"
                  disabled={formikForm.values.IsPermanent}
                />
                <CheckableButton
                  checked={formikForm.values.IsPermanent}
                  label="Le document n'a pas de date de validité"
                  onChange={() => formikForm.setFieldValue('IsPermanent', !formikForm.values.IsPermanent)}
                />
              </DateFieldContainer>
            </div>
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default LegalDocumentsButton
