/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type {
  Adresse,
  PiedDevis,
  Dossier,
} from 'API/__generated__/Api'
import type { Line } from '../QuoteInvoicePage'

/* Component declaration ---------------------------------------------------- */
interface QuoteInvoicePrintButtonProps {
  calculs?: PiedDevis;
  title: string;
  caseInfos: Dossier;
  lines: Line[];
}

const QuoteInvoicePrintButton: React.FC<QuoteInvoicePrintButtonProps> = ({
  calculs,
  caseInfos,
  title,
  lines,
}) => {

  const getAddressStreet = (address?: Adresse | null): string => {
    return [
      address?.adresse1,
      address?.adresse2,
      address?.adresse3,
    ].join(' ')
  }

  const getAddressCity = (address?: Adresse | null): string => {
    return [
      address?.codePostal,
      address?.ville,
    ].join(' ')
  }

  const onPrintClick = () => {
    const printWindow = window.open('', '_blank')
    if (printWindow) {
      printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="fr">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${title}</title>
            <style>
              .container {
                display: flex;
                flex-direction: column;
                color: #476388;
                font-family: "Rubik", sans-serif;
                font-size: 1rem;
                line-height: 1.5;
                padding: 100px 50px;
              }
              .insurance {
                margin-left: 50vw;
                margin-bottom: 20px;
              }
              .table-header {
                background-color: #476388;
                color: white;
                border-radius: 5px;
                padding: 5px;
                margin: 10px 0px;
              }
              .table-line {
                display: grid;
                grid-template-columns: 4fr 1fr 1fr 1.5fr 1fr 1.5fr;
                gap: 10px;
              }
              .border {
                padding-right: 2px;
                border-right: 1px solid white;
              }
              .price {
                justify-self: right;
                padding-right: 10px;
              }
              .quantity {
                justify-self: center;
              }
              .table-footer {
                padding-left: 50%;
                padding-bottom: 10px;
                margin-top: 40px;
              }
              .footer-line {
                display: flex;
                justify-content: space-between;
              }
              .total {
                background-color: #ECEFF3;
                padding: 1px 4px;
                border-radius: 4px;
                position: relative;
                right: -4px;
              }
              @media print {
                .table-header, .total {
                  -webkit-print-color-adjust: exact;
                  print-color-adjust: exact;
                }
              }
            </style>
          </head>
          <body>
            <div class="container">
              <div class="company">
                ${caseInfos?.etablissement?.raisonSociale}<br />
                ${getAddressStreet(caseInfos?.etablissement?.coordonnees.adresse)}<br />
                ${getAddressCity(caseInfos?.etablissement?.coordonnees.adresse)}<br />
                ${caseInfos?.etablissement?.coordonnees.email}<br />
              </div>
              <div class="insurance">
                <b>${caseInfos?.plateforme?.nom}</b><br />
                ${getAddressStreet(caseInfos?.plateforme?.adresse)}<br />
                ${getAddressCity(caseInfos?.plateforme?.adresse)}<br />
                ${caseInfos?.plateforme?.email}<br />
              </div>
              <b>${title}</b>
              <div>${`Date d'émission : ${new Date().toLocaleDateString('fr-FR')}`}</div>
              <div class="table-header table-line">
                <div class="border">Désignation</div>
                <div class="border">Quantité</div>
                <div class="border">Unité</div>
                <div class="border">Prix unitaire</div>
                <div class="border">TVA</div>
                <div>Montant HT</div>
              </div>
              ${lines.map((line) => `
                <div class="table-line">
                  <div>${line.libelle}</div>
                  <div class="quantity">${line.quantite}</div>
                  <div class="quantity">${line.unite}</div>
                  <div class="price">${line.prixUnitaire?.toFixed(2)} €</div>
                  <div class="quantity">${line.tva}%</div>
                  <div class="price">${line.prixHT?.toFixed(2)} €</div>
                </div>
              `).join('')}
              <div class="table-footer">
                <div class="footer-line">
                  <div>Total HT</div>
                  <b>${calculs?.montantHT?.toFixed(2)} €</b>
                </div>
                <div class="footer-line">
                  <div>TVA</div>
                  <b>${calculs?.montantTVA?.toFixed(2)} €</b>
                </div>
                <div class="footer-line">
                  <b>Total TTC</b>
                  <b class="total">${calculs?.montantTTC?.toFixed(2)} €</b>
                </div>
              </div>
            </div>
          </body>
        </html>
      `)

      setTimeout(() => {
        printWindow.print()
      }, 500)
    }
  }

  return (
    <Button
      variant="contained"
      onClick={onPrintClick}
    >
      Imprimer
    </Button>
  )
}

export default QuoteInvoicePrintButton
